import * as bootstrap from 'bootstrap'
import $ from 'jquery';
import Config from '../Config';

let LISTENER = '';
const SINGLE_QUOTE = '-*-*-';

function command(row, username) {
    $('body').append(getDialogHTML(row, username));

    const commandModal = new bootstrap.Modal('#commandmodal');
    const modalElement = document.getElementById('commandmodal');
    const interval = (row.screenshotinterval || Config.SCREENSHOT_INTERVAL) / 1000;

    function dispose() {
        commandModal.dispose();
        $('#commandmodal').remove();
    }

    modalElement.addEventListener('hidden.bs.modal', dispose);

    $('input[name="command"]').on('click', () => {
        let command = $('input[name="command"]:checked').val();

        if (command === 'screenshot') {
            $('#screenshotinterval').removeAttr('disabled').removeAttr('readonly');
        }
        else {
            $('#screenshotinterval').val(interval);
            $('#screenshotinterval').attr('disabled', true).attr('readonly', true);
        }
    });

    $('#commandmodal #sendcommand').on('click', e => {
        e.preventDefault()
        e.stopPropagation()
        $('.invalid-feedback').hide();
        
        const form = $('.needs-validation')[0];
        const rowData = JSON.parse($('input[name="rowdata"]').val().replaceAll(SINGLE_QUOTE, "'"));
        let command = $('input[name="command"]:checked').val();

        if (!form.checkValidity()) {
            if (!command) {
                $('.invalid-command').show();
            }
            else {
                $('.invalid-interval').show();
            }
            return;
        }

        if (command === 'screenshot') {
            const interval = parseInt($('#screenshotinterval').val());

            rowData.screenshotinterval = (interval * 1000).toString();
        }

        command = command.toUpperCase();

        if (LISTENER) {
            LISTENER({ command, rowData });
        }
        
        commandModal.hide();
    });

    commandModal.show();
}

function setListener(listener) {
    if (listener) {
        LISTENER = listener;
    }
}

function getDialogHTML(row, username) {
    const interval = (row.screenshotinterval || Config.SCREENSHOT_INTERVAL) / 1000;
    let type = 'Device';
    let name = row.device_name;
    let s = '';

    const date = new Date(row.lastreboot);
    const lastreboot = date.toLocaleString();
    const logging = row.logging === 'true' ? 'OFF' : 'ON';

    if (row.wall.length) {
        type = 'Display';
        name = row.display_name;
        s = 's';
    }

    let html = `
    <div class="modal fade" id="commandmodal" tabindex="-1" aria-labelledby="commandmodallabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <form class="needs-validation" novalidate>
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="commandmodallabel">${type} Command</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Send command to ${type}: <span class="bold">${name}</span> on: "${row.client}"</p>
                    <input type="hidden" name="rowdata" value='${JSON.stringify(row).replaceAll("'", SINGLE_QUOTE)}' />
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="command" id="commandreboot" value="reboot" required />
                        <label class="form-check-label" for="commandreboot">
                            <span class="bold">Reboot ${type}</span> <span class="small">last reboot: ${lastreboot}</span>
                        </label>
                    </div>`
                    if (username !== 'rutters-support') {
                        html += `
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="command" id="commandrestart" value="restart" />
                                <label class="form-check-label" for="commandrestart">
                                    <span class="bold">Restart App${s}</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="command" id="commandupdate" value="unzip" />
                                <label class="form-check-label" for="commandupdate">
                                    <span class="bold">Update App${s}</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="command" id="commandlogging" value="logging" />
                                <label class="form-check-label" for="commandlogging">
                                    <span class="bold">Logging ${logging}</span>
                                </label>
                            </div>
                            <div class="form-check form-check form-check-inline">
                                <input class="form-check-input mt-1" type="radio" name="command" id="commandscreenshot" value="screenshot" />
                                <label class="form-check-label" for="commandscreenshot">
                                    <span class="bold">Screenshot Interval</span>
                                </label>
                                <div class="invalid-feedback invalid-interval">
                                    Interval must be:
                                </div>
                                <div class="invalid-feedback invalid-command">
                                    Select a command
                                </div>
                            </div>
                            <div class="form-check form-check form-check-inline interval">
                                <input type="number" class="form-check-label small" id="screenshotinterval" min="10" max="300" value="${interval}" readonly disabled required>
                                <label class="form-check-label" for="screenshotinterval">
                                    <span class="small">Seconds for 5 minutes</span>
                                </label>
                                <div class="invalid-feedback invalid-interval">
                                    10 - 300
                                </div>
                                <div class="invalid-feedback invalid-command">
                                    &nbsp;
                                </div>
                            </div>`
                    }
                    html += `
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button id="sendcommand" type="submit" class="btn btn-primary">Send Command</button>
                        </div>
                    </form>
                </div>
            </div>
            </div>`

    return html;
}

const modalInterface = {
    command,
    setListener
};

export default modalInterface;