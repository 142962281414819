/* src/App.js */
import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import $ from 'jquery';
import _ from 'lodash';
import 'gasparesganga-jquery-loading-overlay';
import '@aws-amplify/ui-react/styles.css';
import Render from './components/Render';
import Table from './components/Table';
import ClientDeviceData from './components/ClientDeviceData';
import WebSocket from './components/WebSocket';
import Modal from './components/Modal';
import Config from './Config';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const App = ({ signOut, user }) => {
    let CLIENTS_TABLE;
    let DEVICES_TABLE;
    let WALL_TABLE;
    let CLIENT = '';
    let APP_RENDERED = false;
    let OPEN_ROWS = [];

    function navigation() {
        const title = $('#title');
        const backLink = $('#back');
        const downOnly = $('#downonly');
        const interval = $('#interval');
    
        backLink.on('click', () => {
            window.location.href = '/';
        });

        downOnly.on('click', e => {
            if ($(e.target).text() === 'Show All Devices') {
                window.location.href = `/?client=${CLIENT}&downonly=`;
            }
            else {
                window.location.href = `/?client=${CLIENT}&downonly=true`;
            }
        });

        interval.val(ClientDeviceData.getInterval());

        interval.on('change', e => {
            const url = new URL(window.location.href);
            const client = url.searchParams.get('client') || '';
            const downonly = url.searchParams.get('downonly') || '';
            const interval = parseInt(e.target.value);

            ClientDeviceData.setOptions({ client, downonly, interval });
        });
    
        backLink.hide();
        downOnly.hide();
        title.text('Client list');
    }

    function renderTables() {
        const deviceTableColumns = [
            { defaultContent: '', orderable: false, render: Render.details },
            { defaultContent: '', data: 'client', title: 'Client' },
            { defaultContent: '', data: 'macAddress', title: 'MAC Address' },
            { defaultContent: '', title: 'Site Name', render: Render.siteName },
            { defaultContent: '', title: 'Display Name', render: Render.displayName },
            { defaultContent: '', title: 'Device Name', render: Render.deviceName },
            { defaultContent: '', title: 'Device Version', render: Render.deviceVersion },
            { defaultContent: '', title: 'App Name', render: Render.appName },
            { defaultContent: '', title: 'OS', render: Render.operatingSystem },
            { defaultContent: '', data: 'model', title: 'Model' },
            { defaultContent: '', data: 'logging', title: 'Logging', render: Render.logging },
            { defaultContent: '', data: 'serverversion', title: 'Server Version', render: Render.serverVersion },
            { defaultContent: '', data: 'unixepoch', title: 'Last Ping', render: Render.dateTime },
            { defaultContent: '', data: 'screenshot', title: 'Screenshot', render: Render.screenshot }
        ];
        
        CLIENTS_TABLE = new Table({
            container: 'clients',
            dataTable: {
                autoWidth: false,
                order: [0, 'asc'],
                columns: [
                    { defaultContent: '', data: 'client', title: 'Client' },
                    { defaultContent: '', data: 'devices', title: 'Devices' },
                    { defaultContent: '', data: 'warning', title: 'Warning', render: Render.warning },
                    { defaultContent: '', data: 'down', title: 'Offline', render: Render.error },
                    { defaultContent: '', title: 'View', render: Render.links }
                ],
                fixedHeader: {
                    header: false,
                    footer: true
                },
                footerCallback: function(tfoot) {
                    const api = this.api();
                    const devices = api.column(1).data().reduce((a, b) => {
                        return a + b;
                    }, 0);
                    const warning = api.column(2).data().reduce((a, b) => {
                        return a + b;
                    }, 0);
                    const offline = api.column(3).data().reduce((a, b) => {
                        return a + b;
                    }, 0);

                    $(tfoot).find('th').eq(0).html('TOTALS');
                    $(tfoot).find('th').eq(1).text(devices);
                    $(tfoot).find('th').eq(2).html(Render.warning(warning));
                    $(tfoot).find('th').eq(3).html(Render.error(offline));

                    const downLink = `?client=&downonly=true`;

                    $(tfoot).find('th').eq(4).html(`<a href="${downLink}" class="viewdown">All Down</a>`);
                }
            }
        });
    
        DEVICES_TABLE = new Table({
            container: 'devices',
            dataTable: {
                autoWidth: false,
                dom: '<"tableheader"fl><t><"tablefooter"ip>',
                order: [3, 'asc'],
                paging: true,
                pagingType: 'full',
                columns: deviceTableColumns,
                columnDefs: [
                    { type: 'natural-nohtml', targets: '_all', },
                    { targets: 0, 'createdCell': (td, d, row) => {
                        $(td).parent().attr('id', `mac-${row.macAddress}`);

                        let highlight = false;
                        let dateTime = Render.dateTime(row.unixepoch);

                        if (dateTime.includes('error') || row.oopsscreen === 'true') {
                            highlight = true;
                        }
        
                        if (row.wall && row.wall.length) {
                            $(td).addClass('details-control');
                            
                            _.forEach(row.wall, device => {
                                dateTime = Render.dateTime(device.unixepoch);
                                
                                if (dateTime.includes('error') || device.oopsscreen === 'true') {
                                    highlight = true;
                                }
                            })
                        }
        
                        if (highlight) {
                            $(td).addClass('highlight');
                        }
                        else {
                            $(td).removeClass('highlight');
                        }
                    }}
                ]
            }
        });

        DEVICES_TABLE.table.addClass('devices');

        WALL_TABLE = new Table({
            container: 'wall',
            dataTable: {
                autoWidth: false,
                dom: '',
                order: [3, 'asc'],
                columns: deviceTableColumns,
                columnDefs: [
                    { type: 'natural-nohtml', targets: '_all', },
                    { targets: 0, 'createdCell': (td, d, row) => {
                        $(td).addClass('slave');
                        $(td).parent().attr('id', `mac-${row.macAddress}`);
                        $(td).parent().attr('data-parent', `${row.parent}`);

                        let dateTime = Render.dateTime(row.unixepoch);
      
                        if (dateTime.includes('error') || row.oopsscreen === 'true') {
                            $(td).addClass('highlight');
                        }
                        else {
                            $(td).removeClass('highlight');
                        }
                    }}
                ],
                language: {
                    infoFiltered: '',
                    emptyTable: 'Failed to load additional devices. Make sure the Video Wall is configured correctly on OPEN.'
                }
            }
        });

        $('.devices').on('click tap', 'tbody tr td:not(:first-child)', function(e) {
            e.preventDefault();

            let tr = $(this).closest('tr');
            let table = $(this).closest('table');

            if (!table.hasClass('devices')) {
                let parent = $(this).parent().data('parent');

                tr = $(`#mac-${parent}`);
                table = $(this).closest('table.devices');
            }

            const row = table.DataTable().row(tr).data();

            // app doesn't support commands via websocket yet
            if (!row.serverversion) {
                return;
            }

            Modal.command(row, user.username);
        });

        $('.devices').on('click tap', 'td.details-control', function (e) {
            e.preventDefault();

            const td = $(this);
            const tr = $(this).closest('tr');
            const table = $(this).closest('table');
            const row = table.DataTable().row(td);
            const id = td.parent().attr('id');

            if (!table.hasClass('devices')) {
                return;
            }

            if (td.hasClass('shown')) {
                td.removeClass('shown');
                row.child().hide();

                OPEN_ROWS.filter((value, index, arr) => {
                    if (value === id) {
                        arr.splice(index, 1);
                        return true;
                    }

                    return false;
                });
            }
            else {
                const div = $('<div>').addClass('loading').text('Loading...');

                row.child(div).show();
                row.child().addClass('extra');
                td.addClass('shown');

                let reverse = false;

                if (tr.hasClass('odd')) {
                    reverse = true;
                }

                const clone = cloneWallTable(row.data().wall, reverse);

                // set the width of each clone>tr>td to each parent>td
                clone.find('tr').each((idx, cloneRow) => {
                    $(cloneRow).find('td').each((idx, cloneTd) => {
                        $(cloneTd).css('width', $(tr.find('td')[idx]).css('width'));
                    })
                });

                row.child().find('div').replaceWith(clone);

                if (OPEN_ROWS.indexOf(id) === -1) {
                    OPEN_ROWS.push(id);
                }
            }
        });

        $('#devices').on('mouseenter', 'td .screenshot', async function() {
            const td = $(this).closest('td');
            const row = $(this).closest('table').DataTable().row(td).data();
            const src = $(this).prop('src');
            const url = new URL(src);
            const epoch = new Date();

            url.search = epoch.getTime();

            const rsp = await fetch(url);
            const lastModified = rsp.headers.get('Last-Modified');
            const date = new Date(lastModified);
            const local = date.toLocaleString();
            const interval = ` Interval ${(row.screenshotinterval || Config.SCREENSHOT_INTERVAL) / 1000} seconds`;

            $(this).prop('title', `${local}${interval}`);
        })
    }

    function cloneWallTable(devices, reverse)  {
        WALL_TABLE.update(devices);

        const clone = $('#wall').clone(true);

        clone.attr('id', '');
        clone.addClass('wall');
        clone.css('display', 'block')
        clone.find('thead').remove();

        if (reverse) {
            clone.find('tr').each(function(index, row) {
                if ($(row).hasClass('odd')) {
                    $(row).addClass('even');
                    $(row).removeClass('odd');
                }
                else {
                    $(row).addClass('odd');
                    $(row).removeClass('even');
                }
            })
        }

        return clone;
    }

    async function viewDevices(client = '', downonly = '') {
        const clientsTable = CLIENTS_TABLE.dataTable();
        const rowData = clientsTable.row($(this).parent()).data();

        client = (rowData && rowData.client) ? rowData.client : client;

        if (client && downonly) {
            $('#downonly').text('Show All Devices');
        }

        $('#clients_wrapper').hide();
        $('#devices').show();
        $('#back').show();
        $('#devices .tableheader').append($('.inline'));

        await setClient(client, downonly);

        if (client) {
            const serverVersion = Render.getServerVersion(client);
            let v = '';

            if (serverVersion) {
                v = ` v${serverVersion}`;
                v = Render.versionText(v);
            }

            $('#downonly').show();
            $('#title').html(`Devices on ${client}${v}`);
            DEVICES_TABLE.dataTable().column(1).visible(false);
            WALL_TABLE.dataTable().column(1).visible(false);
        }
        else {
            $('#title').text('All Down Devices');
        }
    }

    function deviceListener(items) {
        if (!_.isArray(items)) {
            items = items.devices;
        }

        const url = new URL(window.location.href);
        const downonly = url.searchParams.get('downonly');
        
        if (CLIENT || (!CLIENT && downonly)) {
            items = combineWallDevices(items);
            DEVICES_TABLE.update(items);
            openRows();
        }
        else {
            items = getClientList(items);
            CLIENTS_TABLE.update(items);
        }

        $.LoadingOverlay('hide');
    }

    async function wsListener(data) {
        console.log(JSON.parse(data.data));

        await Render.refreshData();
        await ClientDeviceData.refreshData();
    }

    function modalListener(data) {
        if (data && data.command && data.rowData) {
            const devices = [];
            const rowData = data.rowData;
            let params = '';

            if (data.command === 'UNZIP') {
                let filename = `${rowData.app_package}-${rowData.app_version}`;
                let extension = 'zip';

                if (rowData.operatingSystem === 'Tizen') {
                    filename = 'abierto';
                    extension = 'wgt';
                }
                else if (rowData.wall.length) {
                    filename = 'com.lg.app.signage';
                    extension = 'ipk';
                }
             
                params = `dce-apps/${rowData.app_id}/${filename}.${extension}`;
            }
            else if (data.command === 'SCREENSHOT') {
                params = rowData.screenshotinterval;
            }

            // push first device connectionId
            devices.push(rowData.connectionId);

            // if device has a wall, push connectionId for each slave
            rowData.wall.forEach(slave => {
                devices.push(slave.connectionId);
            });

            devices.forEach(connectionId => {
                WebSocket.send('getmessage', { command: data.command, commandToId: connectionId, params });
            });
        }
    }

    function openRows() {
        OPEN_ROWS.forEach(mac => {
            $(`#${mac} td:first-child`).trigger('click');
        });
    }

    function combineWallDevices(items) {
        const displays = {};

        items.forEach(item => {
            let displayName = Render.displayName(null, null, item);

            if (!displayName) {
                return true;
            }
            
            _.merge(item, Render.getDevice(item));
        });

        items = _.sortBy(items, ['display_name', 'display_info.screen']);

        items.forEach(item => {
            let displayName = Render.displayName(null, null, item);

            if (displayName === '') {
                displayName = item.device_macaddress;
            }

            if (!displayName) {
                return true;
            }

            if (!displays[displayName]) {
                displays[displayName] = item;
                displays[displayName].wall = [];
            }
            else {
                if (!displays[displayName].wall) {
                    displays[displayName].wall = [];
                }

                if (displays[displayName].client === item.client) {
                    item.parent = displays[displayName].device_macaddress;
                    displays[displayName].wall.push(item);
                }
            }
        });

        items = [];

        for (var display in displays) {
            items.push(displays[display]);
        }

        return items;
    }

    function getClientList(items) {
        const clients = {};
    
        function addClient(item) {
            if (!clients[item.client]) {
                clients[item.client] = {
                    devices: 0,
                    down: 0,
                    warning: 0
                };
            }
    
            // add device
            clients[item.client].devices += 1;
    
            const diff = Date.now() - parseInt(item.unixepoch);
    
            // check if device is down
            if (diff > Config.OFFLINE_TIMEOUT || item.oopsscreen === 'true') {
                clients[item.client].down += 1;
            }
            else if (diff > Config.WARNING_TIMEOUT) {
                clients[item.client].warning += 1;
            }
        }

        items.forEach(item => {
            if (item.hasOwnProperty('macAddress')) {
                const device = Render.getDevice(item);
                const displayName = Render.displayName(null, null, item);

                if (device && displayName) {
                    addClient(item);
                }
            }
        });

        const clientList = [];
    
        for (var client in clients) {
            clientList.push({
                client: client,
                devices: clients[client].devices,
                down: clients[client].down,
                warning: clients[client].warning
            });
        }
    
        return clientList;
    }

    async function init() {
        if (user.username && !APP_RENDERED) {
            $.LoadingOverlay('show');
            // prevent double init
            APP_RENDERED = true;
            // setup navigation
            navigation();

            // render data tables
            renderTables();
            // set listeners
            ClientDeviceData.setListener(deviceListener);
            WebSocket.setListener(wsListener);
            Modal.setListener(modalListener);
            // connect websocket
            WebSocket.connect();

            const url = new URL(window.location.href);
            const client = url.searchParams.get('client') || '';
            const downonly = url.searchParams.get('downonly') || '';

            if (user.username === 'rutters-support') {
                await viewDevices('rutters', downonly);

                // hide back link
                $('#back').hide();
            }
            else if (client || (!client && downonly)) {
                await viewDevices(client, downonly);
            }
            else if (!client && !downonly) {
                await setClient();
            }
        }
    }

    async function logout() {
        $('#container').hide();

        CLIENT = '';
        ClientDeviceData.stopTimer();

        signOut();

        user.username = '';
    }

    async function setClient(client = '', downonly = '') {
        CLIENT = client;

        await Render.setOptions({ client: client, downonly: downonly });
        ClientDeviceData.setOptions({ client: client, downonly: downonly });
    }
    
    useEffect(() => {
        init();
    });

  return (
    <div id='container'>
        <div id='header'>
            <Heading id='title' level={1}></Heading>
            <div id='loggedin'>
                <div>Hello {user.username}</div>
                <Button id='logout' onClick={logout}>Sign out</Button>
            </div>
        </div>
        <div id='navigation'>
            <Button id='back' href='#'>Back to Client list</Button>
            <Button id='downonly' href='#'>Show Down Devices</Button>
            <div className='inline'>
                <label>
                    Refresh every
                    <select id='interval' className='form-select form-select-sm'>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='30'>30</option>
                        <option value='40'>40</option>
                        <option value='50'>50</option>
                        <option value='60'>60</option>
                    </select>
                    seconds
                </label>
            </div>
        </div>
        <table id='clients'>
            <thead></thead>
            <tbody></tbody>
            <tfoot>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
        <div id='devices'></div>
        <div id='wall'></div>
    </div>
  )
}

export default withAuthenticator(App, {
    hideSignUp: true
});
